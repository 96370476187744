/* Add these to Secondlayer.css or your global CSS */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Second layer of the about me page */
.second-layer {
  text-align: center;
  background-color: #ffffff;
  padding: 3%;
  height: auto;
}

.second-layer h1 {
  margin: 1%;
}

/* Change paragraph size to 20px */
.second-layer p {
  font-size: 20px;
}

.location,
.plans,
.media {
  padding: 2%;
}

.grad-slider {
  max-width: 600px;
  margin: 20px auto;
  padding-top: 2%;
  padding-bottom: 2%;
}

.slide-image {
  width: 100%;
  max-height: 550px;
  object-fit: cover;
  border-radius: 10px;
}