.showcase {
    animation: showup 5s;
    padding: 2%;
    text-align: center;
}

/* Earsketch general styling */
.HealthHackED {
    padding: 3%;
    text-align: center;
    font-style: 'Times New Roman', Times, serif;;
    background-color: #ffffff;
}

.AppInfo301 {
    padding: 3%;
    text-align: center;
    font-style: 'Times New Roman', Times, serif;;
    background-color: #ffffff;
}

/* Earsketch header */
.header {
    padding: 2%;
    border: black solid 1px;
}

/* Earsketch paragraph */
p {
    font-size: 19px;
}

/* Margin for audio file */
.earsketch-audio {
    margin: 3%;
}

.receipt-screenshots {
    padding: 3%;
}
